import {FC, useMemo} from 'react';

import noImagePlaceholder from '../../../assets/img/no_image.png';
import {ONE_FIELDS, ONE_ORG_NAME} from '../../../shared/constants/path';
import {generatedUrlOrgName} from '../../../shared/generated-url-org-name';
import {useResizeWindow} from '../../../shared/hooks/use-resize-window';
import {QuickJumpButton} from '../../buttons/quick-jump-button/quick-jump-button';
import {Spoiler} from '../../Spoiler/Spoiler';
import {
	PlatformCardContactsList,
} from '../platform-card/components/platform-card-contacts-list/platform-card-contacts-list';
import {Props} from '../platform-card/components/platform-list-card/platform-list-card';
import * as SC from './platform-list-card-map.style';

export const PlatformListCardMap: FC<Props> = ({
												   platform,
												   onClickToPlatform,
											   }) => {

	const width = useResizeWindow();
	const isMobileMod = width <= 1200;
	const onClickToPlatformHandler = () => {
		onClickToPlatform?.(platform?.id);
	};
	const orgLogo = useMemo(() => platform.org_logo.find(image => image.info.size_label === '1:1')?.info.image_link || noImagePlaceholder, [platform.id]);
	const platformLogo = useMemo(() => platform.logo.find(image => image.info.size_label === '16:9')?.info.image_link || noImagePlaceholder, [platform.id]);

	return (
		<SC.PlatformCard>
			<SC.PlatformCardLogoWrapper>
				<SC.PlatformCardLogo className="logo"
									 src={platformLogo}
									 alt={`Изображение - ${platform?.org_name}`}
									 title={`Изображение - ${platform?.org_name}`}
				/>
				<QuickJumpButton onClick={onClickToPlatformHandler} text={'Быстрый просмотр'} type={'link'}
								 to={ONE_FIELDS.replace(':id', platform.id)}/>
			</SC.PlatformCardLogoWrapper>
			<SC.PlatformCardMainInfo>
				<SC.PlatformCardTitle>{platform?.org_name}</SC.PlatformCardTitle>
				<SC.LogoMobile to={`/${ONE_ORG_NAME}`.replace(':name', generatedUrlOrgName(platform.org_name))}>
					<SC.PlatformCardOrganizationLogo
						src={orgLogo}
						alt={`Логотип - ${platform.org_name}`}
						title={`Логотип - ${platform.org_name}`}
					/>
				</SC.LogoMobile>
				<PlatformCardContactsList platform={platform}/>
			</SC.PlatformCardMainInfo>
			<SC.PlatformCardAdditionalInfo>
				<SC.LogoDesktop to={`/${ONE_ORG_NAME}`.replace(':name', generatedUrlOrgName(platform.org_name))}>
					<SC.PlatformCardOrganizationLogo
						src={orgLogo}
						alt={`Логотип - ${platform.org_name}`}
						title={`Логотип - ${platform.org_name}`}
					/>
				</SC.LogoDesktop>
				{!isMobileMod &&
					<SC.PlatformCardInfoBlockDescription>{platform?.description}</SC.PlatformCardInfoBlockDescription>}
				{isMobileMod && <Spoiler description={platform?.description}/>}
			</SC.PlatformCardAdditionalInfo>
		</SC.PlatformCard>
	);
};
