import {FC, memo} from 'react';
import {Link} from 'react-router-dom';

import vector from '../../../../../assets/icon/arrow_white.svg';
import no_image from '../../../../../assets/img/no_image.png';
import {SERVICE_PAGE} from '../../../../../shared/constants/path';
import * as SC from './service-card.style';

interface ServiceCardProps{
	logo?: string;
	title: string;
	id: string
}

export const ServiceCard:FC<ServiceCardProps> = memo(({logo, title, id}) => {
	const url = `${SERVICE_PAGE}?service_id=${id}`;

	return (
		<Link to={url}><SC.Container>
			<SC.ContentWrapper>
				<SC.Logo src={logo || no_image} alt={title}/>
				<h3>{title}</h3>
			</SC.ContentWrapper>
			<SC.Vector src={vector} alt="vector"/>
		</SC.Container></Link>
	);
});
