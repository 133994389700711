import classNames from 'classnames';
import {isEmpty, omitBy} from 'lodash';
import {useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {CalendarField, FilterWithInput, SingleSelect, TimeField} from '../../../components';
import {useAppSelector} from '../../../redux/selectors/hook';
import {allServicesTypeSelector, arrSportStringSelector} from '../../../redux/selectors/sport-state-selectors';
import {useAppDispatch} from '../../../redux/store';
import {fetchGetAllTypesServices} from '../../../redux/thunks/services/get-all-types-services';
import {ORDER} from '../../../shared/constants/path';
import {AMOUNT_FILTER_OPTIONS} from '../../../shared/constants/service/filters';
import {useFields} from '../../../shared/hooks/use-fields';
import {converterTimeLocalString} from '../../../shared/utils/time/converter-time';
import IconCalendarShape from '../../svg-icon-components/icon-calendar-shape';
import IconFigureShape from '../../svg-icon-components/icon-figure-shape';
import IconSettingsShape from '../../svg-icon-components/icon-settings-shape';
import IconTennisShape from '../../svg-icon-components/icon-tennis-shape';
import IconWatchShape from '../../svg-icon-components/icon-watch-shape';
import {DEFAULT_FILTERS_DATA, QUICK_ORDER_FILTERS} from './constants';
import st from './form-quick-order.module.css';

// TODO: refactor it
export const FormQuickOrder = () => {
	const {fields: filters, onChangeField: onChangeFilter} = useFields(DEFAULT_FILTERS_DATA);

	const serviceTypes = useAppSelector(allServicesTypeSelector);
	const sportTypes = useAppSelector(arrSportStringSelector);

	const dispatchApi = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatchApi(fetchGetAllTypesServices(filters.sports ? {sports: [filters.sports], allow_disabled: false} : {allow_disabled: false}));
	}, [filters.sports]);

	const onChangeTimeFilter = useCallback(
		(from: number | undefined, to: number | undefined) => {
			onChangeFilter(QUICK_ORDER_FILTERS.from, from ? String(from) : '');
			onChangeFilter(QUICK_ORDER_FILTERS.to, to ? String(to) : '');
		},
		[onChangeFilter],
	);

	const onChangeDateFilter = useCallback(
		(date: Date | undefined) => {
			onChangeFilter(QUICK_ORDER_FILTERS.date, date ? converterTimeLocalString(date) : DEFAULT_FILTERS_DATA.date);
		},
		[onChangeFilter],
	);

	const onSubmit = useCallback(() => {
		const resultFilters = omitBy(filters, (value) => !value);
		if (isEmpty(resultFilters)) {
			navigate(ORDER, {
				state: {
					filters: resultFilters,
				},
				replace: true,
			});
			return;
		}
		navigate(ORDER, {
			state: {
				filters: resultFilters,
			},
			replace: true,
		});
	}, [filters]);

	return (
		<div className={st.form_container}>
			<h3 className={st.title}>Попробуйте!</h3>

			<form className={st.form}>
				<FilterWithInput options={sportTypes} placeholder={'Выберите вид спорта'}
								 onChange={(value) => onChangeFilter(QUICK_ORDER_FILTERS.sports, value)}
								 icon={<IconTennisShape/>} valueChange={filters.sports}/>
				<FilterWithInput options={serviceTypes} icon={<IconFigureShape/>}
								 onChange={(value) => onChangeFilter(QUICK_ORDER_FILTERS.service_type, value)}
								 valueChange={filters.service_type} placeholder={'Выберите вид услуги'}/>
				<CalendarField
					icon={<IconCalendarShape/>}
					placeholder="Дата"
					value={filters.date ? new Date(filters.date) : undefined}
					onChange={onChangeDateFilter}
				/>
				<TimeField
					icon={<IconWatchShape/>}
					placeholder="Выберите время"
					fromValue={filters.from ? Number(filters.from) : undefined}
					toValue={filters.to ? Number(filters.to) : undefined}
					onChange={onChangeTimeFilter}
				/>
				<SingleSelect
					clearable={true}
					icon={<IconSettingsShape/>}
					placeholder="Количество"
					value={filters.amount}
					options={AMOUNT_FILTER_OPTIONS}
					onChange={(value) => onChangeFilter(QUICK_ORDER_FILTERS.amount, value)}
				/>
				<button className={classNames('btn_yellow', 'btn_yellow_highlight', st.submit_button)}
					type="button"
					onClick={onSubmit}
				>
					Подобрать услуги
				</button>
			</form>
		</div>
	);
};
