// Import necessary React and React Router hooks
import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    const handlePopState = () => {
      const currentPathName = window.location.pathname;
      const currentSearch = window.location.search;
      if (currentPathName === location.pathname && currentSearch === location.search) {
        navigate(-1);
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [location.pathname, location.search]);

  return null;
};

export default ScrollToTop;