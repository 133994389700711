import {NavLink} from 'react-router-dom';

import {ABONEMENT, ALL_ORG, ORDER, PLAY_FIELDS} from '../../../../shared/constants/path';
import styles from './navbar.module.css';

export const NavBar = () => {
	const stylesCallback = ({isActive}: {
		isActive: boolean
	}) => (isActive ? styles.nav_link_active : styles.nav_link);

	return (
		<nav className={styles.navbar}>
			<ul>
				<li>
					<NavLink to={ORDER} className={stylesCallback}>
						Быстрый Заказ
					</NavLink>
				</li>
				<li>
					<NavLink to={ABONEMENT} className={stylesCallback}>
						Абонементы
					</NavLink>
				</li>
				<li>
					<NavLink to={ALL_ORG} className={stylesCallback}>
						Спортклубы
					</NavLink>
				</li>
				<li>
					<NavLink to={PLAY_FIELDS} className={stylesCallback}>
						Залы
					</NavLink>
				</li>
			</ul>
		</nav>
	);
};
