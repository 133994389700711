import {memo, useCallback} from 'react';
import {useDispatch} from 'react-redux';

import logo from '../../assets/img/logo_footer.png';
import payments from '../../assets/img/payments.png';
import {AuthPages} from '../../interfaces/interfaces';
import {setIsLoginPage, setIsShowRegOrAuth, setStepRegistration1} from '../../redux/reducers/user-data-reducer';
import {useAppSelector} from '../../redux/selectors/hook';
import {isAuthSelector} from '../../redux/selectors/user-state-selectors';
import {
	ABOUT_PROJECT, ALL_ORG, AUTH, CONTACTS, DOCS, ORDER_PAGE, PAYMENT_METHODS,
	PLAY_FIELDS, PROFILE_USER, QUOTE_PAGE
} from '../../shared/constants/path';
import {SERVICES_NAV_LINK_CONFIG} from '../../shared/constants/service/nav';
import {getHasAdministrativeRole} from '../../shared/utils/decoder-jwt';
import {isAuth} from '../../shared/utils/save-local-storage';
import * as SC from './footer.style';

export const Footer = memo(() => {
	const isUserAuthenticatedByStore = useAppSelector(isAuthSelector);
	const isUserAuthenticated = isUserAuthenticatedByStore || isAuth();
	const hasAdministrativeRole = getHasAdministrativeRole();
	const dispatch = useDispatch();

	const handleGoToRegistration = useCallback(() => {
		dispatch(setStepRegistration1());
		dispatch(setIsShowRegOrAuth({type: false}));
		dispatch(setIsLoginPage(false));
	}, []);

	const handleGoToLogin = useCallback(() => {
		dispatch(setStepRegistration1());
		dispatch(setIsShowRegOrAuth({type: true}));
		dispatch(setIsLoginPage(true));
	}, []);

	return (
		<SC.Footer>
			<SC.FooterNavContainer>
				<SC.FooterNav>
					<SC.FooterLogoContainer>
						<SC.Logo src={logo} alt="Логотип - Sporder" title="Логотип - Sporder"/>
						<div>
							<SC.Name>ООО "Румики"</SC.Name>
							<SC.Address>г. Минск, ул. Максима Горецкого, дом 14, пом. 503, кабинет</SC.Address>
							<SC.Contacts>+375255214757, sporder.business@gmail.com</SC.Contacts>
							<SC.Contract>УНП 193756214, выдан Минским горисполкомом 2 апреля 2024 года</SC.Contract>
							<SC.TimeWork>
								<SC.Work>Время работы:</SC.Work>
								<span> с 10:00 до 17:00</span>
							</SC.TimeWork>
						</div>
					</SC.FooterLogoContainer>

					<SC.FooterLinksContainer gridArea={'links'}>
						<SC.FooterLinksTitle>Быстрые ссылки</SC.FooterLinksTitle>
						<ul>
							<li>
								<SC.FooterLink to={ABOUT_PROJECT}>
									О проекте
								</SC.FooterLink>
							</li>
							<li>
								<SC.FooterLink to={DOCS}>
									Договоры оферты
								</SC.FooterLink>
							</li>
							<li>
								<SC.FooterLink to={PAYMENT_METHODS}>
									Оплата услуг
								</SC.FooterLink>
							</li>
							<li>
								<SC.FooterLink to={ORDER_PAGE}>
									Бронирование
								</SC.FooterLink>
							</li>
							{isUserAuthenticated ? (
								hasAdministrativeRole ? (
									<li>
										<SC.FooterLink to={PROFILE_USER}>
											Личный кабинет
										</SC.FooterLink>
									</li>
								) : null
							) : (
								<>
									<li>
										<SC.FooterLink
											state={{page: AuthPages.registration}}
											to={AUTH}
											onClick={handleGoToRegistration}
										>
											Регистрация
										</SC.FooterLink>
									</li>
									<li>
										<SC.FooterLink
											state={{page: AuthPages.login}}
											to={AUTH}
											onClick={handleGoToLogin}
										>
											Вход
										</SC.FooterLink>
									</li>
								</>
							)}
						</ul>
					</SC.FooterLinksContainer>

					<SC.FooterLinksContainer gridArea={'categories'}>
						<SC.FooterLinksTitle>Категории</SC.FooterLinksTitle>
						<ul>
							<li>
								<SC.FooterLink to={ALL_ORG}>
									Спортклубы
								</SC.FooterLink>
							</li>
							<li>
								<SC.FooterLink to={PLAY_FIELDS}>
									Залы
								</SC.FooterLink>
							</li>
							{SERVICES_NAV_LINK_CONFIG.map(({value, label}) => (
								<li key={value}>
									<SC.FooterLink to={value}>
										{label}{' '}
									</SC.FooterLink>
								</li>
							))}
						</ul>
					</SC.FooterLinksContainer>

					<SC.FooterLinksContainer gridArea={'contacts'}>
						<SC.FooterLinksTitle>Контакты</SC.FooterLinksTitle>
						<ul>
							<li>
								<SC.FooterLink to={CONTACTS}>
									Связаться с нами
								</SC.FooterLink>
							</li>
							<li>
								<SC.FooterLink to={QUOTE_PAGE}>
									Партнерам
								</SC.FooterLink>
							</li>
						</ul>
					</SC.FooterLinksContainer>
				</SC.FooterNav>
			</SC.FooterNavContainer>
			<SC.Payments src={payments} alt="payments"/>
			<SC.FooterInfoContainer>
				<SC.FooterInfo>
					<p> &#169; 2024 Sporder. Все права защищены</p>
					<p> Дизайн сделан в студии Старт-Сайт</p>
				</SC.FooterInfo>
			</SC.FooterInfoContainer>
		</SC.Footer>
	);
});
