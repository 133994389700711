import {memo} from 'react';

import * as SC from './registration-conditions.style';

export const RegistrationConditions = memo(() => {

	return (
		<SC.RegistrationConditions>
			Оставляя заявку, вы принимаете условия
			<SC.RegistrationConditionsLink to={'/personal-data-processing-policy'}>{' '} публичного
				договора</SC.RegistrationConditionsLink> и
			<SC.RegistrationConditionsLink to={'/public-contract-rumiki-individuals'}>{' '}
				положения об обработке персональных данных
			</SC.RegistrationConditionsLink>
		</SC.RegistrationConditions>
	);
});
