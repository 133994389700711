import {Suspense, useCallback, useEffect} from 'react';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';

import {Footer, Header, MessageUser} from '../components';
import {Loader} from '../components/loader';
import {setIsShowOrderContainer} from '../redux/reducers/orders-reducer';
import {resizeScreen} from '../redux/reducers/resize-screen-reduser';
import {setOpenForUrl} from '../redux/reducers/user-data-reducer';
import {useAppSelector} from '../redux/selectors/hook';
import {isAuthSelector, isShowLoaderSelector, isShowMessageSelector} from '../redux/selectors/user-state-selectors';
import {useAppDispatch} from '../redux/store';
import {fetchGetAllCity} from '../redux/thunks/get-all-city';
import {AUTH} from '../shared/constants/path';
import {isRightTokenTime} from '../shared/utils/decoder-jwt';
import {isAuth} from '../shared/utils/save-local-storage';
import * as SC from './layuot.style';

const Layout = () => {
	const isShowLoader = useAppSelector(isShowLoaderSelector);
	const isShowMessage = useAppSelector(isShowMessageSelector);
	const param = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const isUserAuthenticatedByStore = useAppSelector(isAuthSelector);
	const isUserAuthenticated = isUserAuthenticatedByStore || isAuth();

	useEffect(() => {
		if (param.id) {
			dispatch(setIsShowOrderContainer({data: false}));
		}
	}, [dispatch, param]);

	useEffect(() => {
		dispatch(fetchGetAllCity());
	}, [dispatch]);

	const saveSizeScreenStore = useCallback(() => {
		dispatch(resizeScreen({size: window.innerWidth}));
	}, [dispatch]);

	useEffect(() => {
		window.addEventListener('resize', saveSizeScreenStore);
		return () => {
			window.removeEventListener('resize', saveSizeScreenStore);
		};
	}, [saveSizeScreenStore]);

	if (!isUserAuthenticated && !isRightTokenTime() &&
		(location.pathname.startsWith('/admin')
			|| location.pathname.startsWith('/profile')
			|| location.pathname.startsWith('/FAQ'))) {
		navigate(AUTH, {replace: true});
		dispatch(setOpenForUrl(location.pathname));
	}

	return (
		<SC.Container>
			{isShowLoader && <Loader/>}
			{isShowMessage && <MessageUser/>}
			<Header/>
			<Suspense fallback={<Loader/>}>
				<Outlet/>
			</Suspense>
			<Footer/>
		</SC.Container>
	);
};
export default Layout;
